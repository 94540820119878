'use client'
import { CartContext } from '@/context'
import { fixTwoDecimal } from '@/utils/functions'
import { useContext } from 'react'

const ProductPrice = ({
  colors, globalTexts, pageTexts, price
}) => {
  const { cost, pvp, discount } = price
  const { cart: { currency }} = useContext(CartContext)
  const hideDiscount = discount <= 0 || cost >= pvp
  return (
    <div id="price-wrapper" className="mb-2">
      {!hideDiscount && <p id="discount" style={{ color: colors.secondary }} className="capitalize font-semibold text-sm">
        {`${globalTexts.discount} ${discount}${globalTexts.percent}`}
      </p>}
      <div id="price" className="pr-2 flex items-end">
        <p id="cost" style={{ color: colors.secondary }} className="pr-4 text-2xl font-bold">
          {`${fixTwoDecimal(cost * (currency?.exchange ?? 1))} ${currency?.symbol}`}
        </p>
        <span id="pvp" className="flex items-center text-base" >
          {!hideDiscount && <p id="cost" className="pr-2 text-neutral-400 font-semibold line-through">
            {`${fixTwoDecimal(pvp * (currency?.exchange ?? 1))} ${currency?.symbol}`}
          </p>}
          <span id="tax-included" className="text-xs text-neutral-400 font-semibold"> {pageTexts?.tax_included} </span>
        </span>
      </div>
    </div>
  )
}

export default ProductPrice
