import { ReactSVG } from "react-svg"
export const SvgOld = ({ id = ``, name, path, click }) => (
  <ReactSVG
    id={id}
    beforeInjection={svg => {
      svg.classList.add(`svg-class-${name}`)
    }}
    src={`${path}`}
    wrapper="div"
    useRequestCache={true}
    onClick={click}
  />
)

export default SvgOld

// export const NativeSvg = ({ field, svgString, wdth }) => (
//   <div className={`svg-class-${field}`} dangerouslySetInnerHTML={{ __html: svgString }} style={{ width: wdth, objectFit: `contain` }} />
// )

// const getFileExtension = file => {
//   const extension = file.substring(`data:image/`.length, file.indexOf(`;base64`))
//   return extension
// }

// export const renderImage = (
//   file, field, hght = 100, wdth = 100
// ) => {
//   const extension = getFileExtension(file)
//   if (extension.includes(`svg`)) {
//     // Verificar que la cadena de la imagen tiene el formato correcto
//     const parts = file.split(`,`)
//     if (parts.length !== 2 || !parts[1]) {
//       console.error(`La cadena de la imagen no tiene el formato correcto.`)
//       return
//     }

//     // Convertir la imagen base64 en una cadena SVG
//     const base64 = parts[1]
//     let svgString
//     try {
//       svgString = atob(base64)
//     } catch (error) {
//       console.error(`Error al decodificar la cadena base64: `, error)
//       return
//     }

//     // Renderizar el SVG
//     return <NativeSvg {...{ svgString, field, wdth }} />
//   }
//   return <Image src={file} alt="logo" width={wdth} height={hght} style={{ objectFit: `contain` }} />
// }
