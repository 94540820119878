import { TWButton } from "@/components/tailwind/Button"
import { CatalogueContext } from "@/context"
import { types } from "@/types"
import { constructPath } from "@/utils/functions/catalogue"
import { useRouter } from "next/navigation"
import queryString from "query-string"
import { useContext } from "react"
import { GiBroom } from "react-icons/gi"

export const RemoveFilter = ({ mobile, meta, search, handleRemove, colors, ...props }) => {
  const { info, handleLoading } = useContext(CatalogueContext)
  const router = useRouter()
  const handleRemoveAll = async e => {
    const params = await queryString.parse(globalThis.window.location.search, { arrayFormat: `bracket-separator`, arrayFormatSeparator: `|` })
    const { mbn, mb, my, mm, s, ...tempParams } = params
    await handleLoading(true)
    await constructPath(
      { mbn, mb, my, mm, s },
      info.pathFilter.filter(x => x.type === types.catalog.meta),
      info.cleanPath,
      router
    )
    await handleRemove()
  }
  return (
    <div className="grid grid-cols-2 sx:m-0">
      {!meta && (
        <TWButton
          {...{ colors }}
          className="bg-slate-100 text-gray-700 text-sm disabled:cursor-default disabled:text-gray-400"
          style={{ padding: `4px`, border: `1px solid ${colors.lightGrey3}`, whiteSpace: `nowrap` }}
          type="outlined"
          disabled={!(info.pathFilter.filter(x => x.type !== types.catalog.meta).length > 0)}
          onClick={handleRemoveAll}
          animated
        >
          <GiBroom sx={{ fontSize: 18 }} />
        </TWButton>
      )}
    </div>
  )
}
