import { handleKeyRandom } from '@/utils/functions'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React from 'react'

export const RadioSelector = props => {
  const {
    setter, arr, value, add, colors
  } = props
  const handleSelect = i => {
    setter(i + add)
  }
  return (
    <RadioGroup
      aria-labelledby={`selector-${value}`}
      overlay="true"
      name={`selector-${value}-channel`}
      defaultValue={0}
      sx={{
        flexDirection: `row`,
        justifyContent: `space-evenly`
      }}
    >
      {arr.map((x, i) => (
        <FormControlLabel
          key={handleKeyRandom(`${value}-${i}`, i)}
          id={x}
          label={x}
          control={
            <Radio
              disableRipple
              size="small"
              value={i}
              onClick={() => handleSelect(i)}
              onChange={() => handleSelect(i)}
              sx={{
                color: colors.color,
                "&.Mui-checked": {
                  color: colors.main,
                },
              }}
            />
          }
        />
      ))}
    </RadioGroup>
  )
}
