import { LegalModal } from "@/app/(web)/account/_components/newsletter/subcomponent"
import { TWButton } from "@/components/tailwind/Button"
import TWInput from "@/components/tailwind/Input"
import { ToastifyContext } from "@/context"
import { useContact } from "@/hooks/contact"
import { useFormAccount } from "@/hooks/utils/useForm"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { types } from "@/types"
import { Icons, validateContactForm, validateOnChange, validateOtherForms } from "@/utils/functions/form"
import { ga, googleConsent, gtagGenerateLead } from "@/utils/functions/google"
import { constants, forms } from "@/utils/global"
import parse from "html-react-parser"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"

const { URL: { MAIL_SEND, BIKE_MAIL_SEND }} = constants

export const BikeContact = props => {
  const { pageTexts, product, hostname, bike = false } = props
  const { bikes, privacyTexts } = pageTexts
  const { accept_policy } = privacyTexts
  const { contact, errorTexts } = bikes
  const { colors, login, business } = hostname
  const { formTitle, formBtn, form } = bikes
  const [ response, setResponse ] = useState(null)
  const { setErrorToastify } = useContext(ToastifyContext)
  const { contactApiCall } = useContact()
  const { tablet } = useMediaQuerys()
  const [ open, setOpen ] = useState(false)
  const [ checked, setChecked ] = useState(false)
  const { input } = types
  const dflData = !bike ? { [input.order]: product?.webdata.h1, legal: false } : { legal: false }
  const {
    register,
    getValues,
    setError,
    setValue,
    clearErrors,
    setFocus,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: dflData
  })
  const { dis, setDis } = useFormAccount(
    `contact`, forms[!bike ? `PRODUCT_FORM` : `BIKE_CONTACT`], getValues, setError, watch, contact, form
  )

  useEffect(() => {
    response && setErrorToastify({ error: response.error, message: contact[response.message.level3] })
  }, [response])

  const sendBikeMail = async() => {
    const valValues = !validateOtherForms(
      forms.BIKE_CONTACT, getValues(), setError, errorTexts
    )
    if (!valValues) {
      setDis(valValues)
      return false
    }
    const values = getValues()
    const corpMsg = `<p>${parse(values.message)}</p> <p>Nombre: ${values.name}</p> <p>Email: ${values.email}</p> ${values.phone !== `` ? `<p>Tlf: ${values.phone}</p>` : ``}<a href=${
      window.location
    }>${product.webdata.title}</a>`
    const data = {
      mail: {
        email: values.email,
        subject: `${hostname?.business?.alias} - ${product.webdata.title}`,
        language: hostname.lang,
        platform: hostname.domain,
        html: corpMsg,

        seller: product?.email || business?.mailReplyTo,
        type: `moto`,
      },
      motoVoid: product.id,
    }

    setDis(true)

    await contactApiCall({ data, setResponse, url: BIKE_MAIL_SEND })
  }

  const sendMail = async() => {
    const valValues = !validateContactForm(
      forms.PRODUCT_FORM, getValues(), setError, errorTexts
    )

    if (!valValues) {
      setDis(valValues)
      return false
    }
    const values = getValues()
    const ref = login?.user.user_id ? values.order.order ? values.order.order : values.order.draft : values.order

    // TODO revisar subject
    const finalSubject = `${hostname.lang} | ${ref ? `${ref} ` : ``} Información de moto - ${values.name}`
    const body = parse(`${values.message} \n\n Nombre: ${values.name} \n Email: ${values.email} \n Tlf: ${values.phone}`)
    const data = {
      mail: {
        productId: product?.id || null,
        email: values.email,
        subject: finalSubject,
        body,
        language: hostname.lang,
        platform: hostname.domain,
        type: `contact`
      },
      update: {
        userId: login?.user.user_id || null,
        name: values.name,
        email: values.email,
        phone: values.phone,
        ref,
        orderId: values.order?.orderId || null,
        productId: product?.id || null,
        message: values.message,
        subjectId: 1,
      },
    }

    setDis(true)

    await contactApiCall({ data, setResponse, url: MAIL_SEND })

    const consent = await googleConsent()
    const formData = {
      name: consent ? values.name : ``,
      email: consent ? values.email : ``,
      phone: consent ? values.phone : ``,
    }
    await ga.event({ action: types.gtag.eventType.generateLead, params: gtagGenerateLead(product, formData, hostname) })
  }

  return (
    <div>
      {bike &&
      <div style={{ backgroundColor: colors.lightGrey }} className="rounded-t-lg p-4 mb-[2px]">
        <div className="text-[24px] font-[500]">
          {formTitle}
        </div>
      </div>
      }
      <div style={{ backgroundColor: colors.lightGrey }} className={`grid grid-cols-2 p-4 min-h-[200] ${!bike && `rounded-t-lg pt-8`}`}>
        {Object.entries(form).map((field, i) => {
          const BTYPE = forms[!bike ? `PRODUCT_FORM` : `BIKE_CONTACT`].find(x => x.type === field[0])
          return (
            <div className="p-[5px]" style={{ gridColumn: tablet ? `span ${BTYPE.colspan}` : `span 2` }} key={`field_${i}`}>
              <TWInput
                {...{ colors }}
                inputbg={colors.white}
                bg={colors.white}
                color={colors.dark}
                type={BTYPE.inputType}
                error={errors[field[0]]?.message}
                starticon={Icons(field[0])}
                id={`field_${i}`}
                {...register(field[0], {
                  required: BTYPE.required,
                  onChange: e => validateOnChange(
                    e.target.value, field[0], clearErrors, setError, setFocus, errorTexts
                  ),
                })}
                name={field[0]}
                placeholder={`${field[1]} ${BTYPE.required ? `*` : ``}`}
              />
            </div>
          )
        })}
        <p className="col-span-2 text-xs text-neutral-500">{`* son campos obligatorios`}</p>
      </div>
      <div style={{ backgroundColor: colors.lightGrey }} className="tablet:flex justify-between rounded-b-lg p-4 pt-0">
        <div className="flex px-2 self-center font-[600] mb-2 tablet:mb-0">
          <input id="rgpd" name="rgpd"
            type="checkbox"
            checked={checked}
            onChange={() => {
              setValue(`legal`, !checked)
              setChecked(!checked)
            }}/>
          <label htmlFor="rgpd" className="flex ml-2 text-sm">
            {accept_policy.text1}
            <span onClick={e => {
              e.preventDefault()
              setOpen(!open)
            }} className="whitespace-pre-wrap hover:cursor-pointer line-clamp-1"
            style={{ color: colors.secondary }}>{` ${accept_policy.text2}`}</span>
          </label>
        </div>
        <div className="w-full tablet:w-auto">
          <TWButton {...{ colors }} className="w-full tablet:px-10" disabled={dis} onClick={bike ? sendBikeMail : sendMail}>
            {formBtn}
          </TWButton>
        </div>
      </div>
      <LegalModal {...props} {...{ open, setOpen, privacyTexts }} />
    </div>
  )
}
