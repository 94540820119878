import TWLabel from '@/components/tailwind/Label'

const VariantButton = ({
  variant, attributes, lang, colors, varInfo, selected, setSelected
}) => {
  const { web_availability: availability, real_stock, sale_ko } = varInfo
  const attrValue = attributes?.find(attr => attr.id === variant.attribute_value) ?? null
  const attrText = attrValue ? JSON.parse(attrValue?.translations)[lang.toLowerCase()] : attrValue?.name ?? null
  const dis = parseInt(sale_ko, 10) === 1 || parseInt(availability, 10) === 3
  const bgColor = {
    3: colors.RedAv,
    default: colors.GreenAv
  }
  const isSelected = selected?.variant_id === variant.variant_id
  return (<div className="relative">
    <button onClick={() => setSelected(variant.variant_id)}
      className={`size-11 m-1 p-1 border rounded-lg font-semibold ${!dis ? `hover:bg-neutral-100 border-neutral-300 ` : `text-neutral-400`} text-sm  `}
      style={{
        background: dis && `linear-gradient(to top left,rgb(0,0,0,0) 0%,rgb(0,0,0,0) calc(50% - 0.8px), ${colors.darkGrey2}88 50%,rgb(0,0,0,0) calc(50% + 0.8px),rgb(0,0,0,0) 100%)`,
        backgroundColor: dis ? colors.lightGrey : isSelected ? colors.main : colors.white,
        color: isSelected ? colors.white : colors.dark
      }}
      disabled={dis}>
      {attrText}
    </button>
    {!dis && <TWLabel {...{ colors }} className="p-1" bgColor={colors.white} minW={`0`} posTop="0" posLeft="36px" >
      <div style={{ backgroundColor: bgColor[availability] ?? bgColor.default }} className="size-2 rounded"/>
    </TWLabel>}
  </div>
  )
}

export default VariantButton
