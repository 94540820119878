import { apiCall } from "@/middleware/api"
import { encodeHashJS } from "@/middleware/api/secure"

export const useContact = () => {
  const contactApiCall = async props => {
    const { data, setResponse, url } = props
    const encrypt = data || ``
    const sendData = {
      url,
      encrypt,
      type: data ? `POST` : `GET`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const response = await apiCall(encriptedData)
    await setResponse(response)
  }
  const contactApiCallData = async props => {
    const { data, setResponse, url } = props
    const encrypt = data || ``
    const sendData = {
      url,
      encrypt,
      type: data ? `POST` : `GET`
    }
    const encriptedData = encodeHashJS(JSON.stringify(sendData), false)
    const { error, message } = await apiCall(encriptedData)
    await setResponse(message.data)
  }
  return {
    contactApiCall,
    contactApiCallData
  }
}

