'use client'
import { SumaryOrder } from "@/app/(web)/account/_components/orders/subcomponents/details/components/Sumary"
import { TWDivider } from "@/components/tailwind/Divider"
import { useApi } from "@/hooks/apicall"
import { fixTwoDecimal } from "@/utils/functions"
import { colors, constants } from '@/utils/global'
import { memo, useEffect, useMemo, useState } from "react"
import { BusinessCodeInfo } from "../component/BusinessCodeInfo"
import { RadioBusinessOney } from "../component/RadioBusinessOney"
import { RadioSelector } from "../component/RadioSelector"

export const SimulatorOney = ({
  price, hostname, texts, businessCode, setBusinessCode, refered, setRefered, payCodes, setPayCodes, show = false, ubi = `cart`, openOney
}) => {
  const { mobile } = hostname
  const oney = useMemo(() => texts?.cart_page.oney, [texts])
  const { dfltApiCall } = useApi()
  const [ infoSim, setInfoSim ] = useState({})
  const handlePayCodes = async() => {
    await dfltApiCall({
      platform: hostname?.id,
      type: ubi,
      price
    },
    setPayCodes,
    constants.URL.ONEY_INFO)
  }

  const handleBusinessCode = () => {
    (!!businessCode || payCodes.pays?.length > 0) && dfltApiCall({
      platform: hostname?.id,
      type: `${ubi}Sim`,
      price,
      businessCode: businessCode || payCodes.pays[0]
    },
    setInfoSim,
    constants.URL.ONEY_INFO)
  }

  const handleParams = (x, type) => {
    const data = {
      text: ``,
      color: colors.darkGrey2,
      weight: 500,
      underline: false
    }
    if (infoSim?.instalments?.length > 0) {
      switch (x) {
      case `total`:
        data.text = `${infoSim.payment_amount}€`
        break
      case `totalOney`:
        data.text = `${fixTwoDecimal(infoSim.payment_amount + infoSim.administration_fee_amount)}€`
        break
      case `today`:
        data.text = `${infoSim.down_payment_amount}€`
        data.color = colors.oney
        data.weight = 700
        break
      case `creditCost`:
        data.text = `${infoSim.total_cost}€`
        break
      case `tae`:
        data.text = `${infoSim.effective_annual_percentage_rate}%`
        data.weight = 800
        data.underline = true
        data.color = colors.darkGrey
        break
      case `tin`:
        data.text = `${infoSim.nominal_annual_percentage_rate}%`
        break
      case `postPays`:
        data.text = `${infoSim.instalments[0].instalment_amount}€`
        break

      default:
        data.text = ``
      }
    }
    return data
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handlePayCodes()
    }, 300)
    return () => clearTimeout(debounceTimeout)
  }, [price])

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      !!businessCode && handleBusinessCode()
    }, 300)

    return () => clearTimeout(debounceTimeout)
  }, [ businessCode, price ])

  useEffect(() => {
    payCodes.pays?.length > 0 && setBusinessCode(payCodes.pays[0])
  }, [ payCodes, price ])

  return !!oney && (
    <div className={`items-center justify-start flex-row flex-wrap mb-10 ${openOney ? `flex` : `hidden`}`}>
      {payCodes.pays?.length > 0 && payCodes.use &&
          <div className="grid grid-cols-1 items-center">
            <div className="flex items-center justify-center">
              {show && <>
                <div className="flex items-center justify-center">
                  <RadioSelector {...{
                    arr: oney.refered,
                    value: `refered`,
                    add: 1,
                    setter: setRefered,
                    colors
                  }} />
                </div>
                <TWDivider vertical size={50}/>
              </>}
              {payCodes.pays?.length > 0 && !!businessCode &&
              <RadioBusinessOney {...{
                arr: payCodes.pays,
                value: `businessCode`,
                setter: setBusinessCode,
                add: ``,
                businessCode,
                colors
              }} />}
            </div>
            <div className="bg-white rounded-md p-2 border border-neutral-200 m-auto min-w-80">
              {
                Object.entries(infoSim)?.length > 0 && Object.entries(oney.simulator).map((x, i) => {
                  const tmpObj = handleParams(x[0])
                  return (<SumaryOrder key={`${x[0]}-${i}`} labela={`${x[1]}`} textcolora={tmpObj.color} labelb={`${tmpObj.text}`} textcolorb={tmpObj.color} upper={`inherit`} weight={tmpObj.weight} underline={tmpObj.underline} />)
                })
              }
            </div>
          </div>}
      {payCodes.tax?.length > 0 && !payCodes.use && ubi !== `prod` && <div
        className="flex items-center justify-center flex-wrap w-full bg-white p-2 rounded-md border border-neutral-200 m-auto">
        <BusinessCodeInfo info={payCodes.tax} texts={oney} />
      </div>}
    </div>
  )
}

export default memo(SimulatorOney)
