import { fixTwoDecimal } from "@/utils/functions"
import { constants } from "@/utils/global"
import IconsSVG from "@/utils/icons/IconsSVG"
import parse from "html-react-parser"

export const BikePrice = props => {
  const { product, currency, colors } = props
  const location = product.attrs && product.attrs.find(att => att.attr_moto_id === 10)
  return (
    <div className="flex mb-2 items-center">
      <p style={{ color: colors.secondary }} className="mr-2 text-[25px] font-[700] mb-0">
        {parse(`${fixTwoDecimal(product.price.cost)} ${currency.symbol}`)}
      </p>
      <IconsSVG name="pindrop" path={`/img/stores/location.svg`}/>
      <p style={{ color: colors.darkGrey2 }} className="text-[18px] font-[600] mb-0">
        {parse(location?.value || ``)}
      </p>
    </div>
  )
}

