'use client'

import { SearchMotos } from "@/app/(web)/_components/home/subcomponents/search/SearchMotos"
import { TWButton } from "@/components/tailwind/Button"
import { ToolsContext } from "@/context/tools/tools"
import useScrollTop from "@/hooks/utils/useScrollTop"
import parse from "html-react-parser"
import { useContext, useMemo } from "react"
import { BsPlus } from "react-icons/bs"

export const HeaderCatalogue = props => {
  useScrollTop()
  const { catalogue, category, hostname, pageTexts } = props
  const { tools: { texts }} = useContext(ToolsContext)
  const globalTexts = useMemo(() => texts.generic, [texts])
  const { colors } = hostname
  const info = category ? catalogue.info : catalogue.webdata
  const handleLink = () => {
    const targetElement = document.getElementById(`catalogue-info`)
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: `smooth`,
        block: `start`
      })
    }
  }

  return (
    <div>
      <div className="tablet:py-1">
        <div className="hidden tablet:block">
          <div>
            <h1 style={{ color: colors.dark, fontSize: 25, fontWeight: 700 }} >
              {parse(info?.h1 || ``) }
            </h1>
          </div>
          <div>
            <h2 style={{ color: colors.dark, fontSize: 17, fontWeight: 600 }} >
              {parse(info?.h2 || ``)}
            </h2>
          </div>
          <TWButton
            {...{ colors }}
            color={colors.main}
            style={{ padding: `0 10px 0 5px`, border: `1px solid ${colors.lightGrey3}` }}
            type="outlined"
            onClick={handleLink}
            animated
          >
            <BsPlus style={{ fontSize: `15px` }}/>
            <p style={{ fontSize: `13px` }}>{globalTexts?.info ?? ``}</p>
          </TWButton>
        </div>
      </div>
      {category && catalogue.info.id === 234 &&
        <div className="my-1">
          <SearchMotos {...props} {...{ hostname, category: true, colors }} />
        </div>
      }
    </div>
  )
}
