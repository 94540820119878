import { cmp } from '@/types'
import { handleKeyRandom } from '@/utils/functions'
import IconsSVG from "@/utils/icons/IconsSVG"
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useId } from 'react'

export const BusinessCodeInfo = props => {
  const { info, texts } = props
  const keyId = useId()
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow cmptype={cmp.styleRow}>
            <TableCell cmptype={cmp.stickyCellCookie} align="center" sx={{ fontWeight: 700 }}>
              {texts.code}
            </TableCell>
            <TableCell cmptype={cmp.styleCell} align="center" sx={{ fontWeight: 700 }}>
              {texts.installments}
            </TableCell>
            <TableCell cmptype={cmp.styleCell} align="center" sx={{ fontWeight: 700 }}>
              {texts.minPrice}
            </TableCell>
            <TableCell cmptype={cmp.styleCell} align="center" sx={{ fontWeight: 700 }}>
              {texts.maxPrice}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {info.map((row, i) => {
            return (
              <TableRow cmptype={cmp.styleRow} key={handleKeyRandom(keyId, i)}>
                <TableCell cmptype={cmp.stickyCellCookie} className="oney" align="center">
                  <IconsSVG name="checkedOney" path={`/img/payment-icons/oney/checked/${row.business_transaction_code}.svg`} />
                </TableCell>
                <TableCell cmptype={cmp.styleCell} align="center">
                  {row.minimum_number_of_instalments}
                </TableCell>
                <TableCell cmptype={cmp.styleCell} align="center">
                  {`${row.minimum_selling_price}€`}
                </TableCell>
                <TableCell cmptype={cmp.styleCell} align="center">
                  {`${row.maximum_selling_price}€`}
                </TableCell>
              </TableRow>)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
