import TWLabel from '@/components/tailwind/Label'
import React from 'react'

const BikeLabel = ({ hostname, isPro }) => {
  const { colors } = hostname

  //   TODO catálogo: cambiar textos por ddbb
  return (
    <TWLabel
      fontSize="16px"
      className="uppercase"
      {...{ colors }}
      bgColor={isPro ? colors.dark : colors.secondary}
      rounded
      text>
      {isPro ? `profesional` : `particular`}
    </TWLabel>
  )
}

export default BikeLabel
