import { handleKeyRandom } from '@/utils/functions'
import SvgOld from '@/utils/icons/SvgOld'
import {
  Box, FormControlLabel, Radio, RadioGroup
} from '@mui/material'
import { IoMdArrowDropup } from 'react-icons/io'

export const RadioBusinessOney = props => {
  const {
    setter, arr, value, businessCode, colors
  } = props

  return (
    <RadioGroup
      aria-labelledby={`selector-${value}`}
      overlay="true"
      name={`selector-${value}-channel`}
      defaultValue={0}
      sx={{
        flexDirection: `row`,
        justifyContent: `space-evenly`,
        marginTop: 1
      }}
    >
      {arr.map((x, i) => {
        return <FormControlLabel
          key={handleKeyRandom(`${value}-${i}`, i)}
          id={x}
          control={
            <Box sx={{
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`
            }}>
              <Radio
                disableRipple
                size="small"
                value={i}
                onClick={() => setter(x)}
                sx={{
                  padding: 0,
                  color: colors.color,
                  height: 25,
                  "&.Mui-checked": {
                    color: colors.main,
                  },
                  "& .svg-class-checkedOney": {
                    width: 45,
                    height: 45,
                    marginLeft: 0
                  }
                }}
                checkedIcon={
                  <SvgOld name="checkedOney" path={`/img/payment-icons/oney/checked/${x}.svg`} />
                }
                icon={
                  <SvgOld name="checkedOney" path={`/img/payment-icons/oney/unchecked/${x}.svg`}/>
                }
              />
              { x === businessCode && <IoMdArrowDropup color={colors.oney} fontSize={28}
                style={{
                  margin: 0,
                  padding: 0
                }} />}
            </Box>
          }
        />
      })}
    </RadioGroup>
  )
}
