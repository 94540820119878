import { TWDivider } from "@/components/tailwind/Divider"
import { cmp } from "@/types"
import { Divider, Grid2, Typography } from "@mui/material"
import React from "react"

export const SumaryForm = props => {
  const {
    labela, labelb, textcolora, textcolorb, colors
  } = props
  return (
    <Grid2 size={{ xs: 12 }}
      sx={{
        justifyContent: `space-between`,
        display: `flex`
      }}>
      <Typography cmptype={cmp.formTexts} textcolor={textcolora}
        wrap={`nowrap`} sx={{
          width: `fit-content`,
          overflow: `inherit`
        }}
        weight={600}>
        {labela}
      </Typography>
      <span style={{
        width: `100%`,
        alignSelf: `center`,
        padding: `0px 16px 0px 0px`
      }}>
        <Divider flexItem sx={{
          width: `100%`,
          borderStyle: `dashed`,
          borderColor: colors.lightGrey3,
          borderWidth: 1,
          marginTop: `10px`
        }} />
      </span>
      <Typography cmptype={cmp.formTexts} textcolor={textcolorb}
        sx={{
          width: `fit-content`,
          overflow: `inherit`,
          marginLeft: `10px`
        }} weight={600}>
        {labelb}
      </Typography>
    </Grid2>
  )
}

export const SumaryOrder = props => {
  const {
    labela, labelb, textcolora, textcolorb, upper, weight, underline
  } = props
  return (
    <div className="w-full flex justify-between items-baseline">
      <p style={{ color: textcolora, fontWeight: weight ?? 600 }} className={`text-nowrap w-fit text-base ${underline ? `underline` : ``} ${upper ? `capitalize` : ``}`}>
        {labela}
      </p>
      <TWDivider my={0} mx={5} dotted/>
      <p style={{ color: textcolorb ?? textcolora, fontWeight: weight ?? 600 }} className={`text-nowrap w-fit text-base ${underline ? `underline` : ``}`}>
        {labelb}
      </p>
    </div>
  )
}
