'use client'
import { DesktopFilters } from "@/app/(web)/_components/filters/desktop"
import { MoreFilter, OrderFilter } from "@/app/(web)/_components/filters/global"
import { CatalogueContext } from "@/context"
import { types } from "@/types"
import { useContext } from "react"

export const MobileFilters = ({ ...props }) => {
  const { info, handleModalFilter } = useContext(CatalogueContext)
  return (
    <div className="grid grid-cols-2 bg-white">
      <MoreFilter {...props } {...{ setMore: handleModalFilter, more: info.modalFilter, filters: info.pathFilter }}>
        {props.children}
        <div className="h-[47vh] overflow-y-scroll">
          <DesktopFilters {...props } {...{ fill: `true`, mobile: true }} />
        </div>
      </MoreFilter>
      <OrderFilter {...props } filters={info.pathFilter.filter(x => x.type === types.catalog.order.order) || []}/>
    </div>
  )
}
