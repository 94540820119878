import CardImage from "@/app/(web)/_components/card/component/CardImage"
import { getImageUrl } from "@/utils/functions/images/imagesHelper"
import Link from "next/link"

export const BrandCard = ({ ...props }) => {
  const { logo, webdata, domain, hostname, modules } = props
  const urlThumb = getImageUrl(logo?.image, hostname?.business?.thumb, domain)
  const cardWidth = 100
  return (
    <Link prefetch={false} href={`${hostname.host}/${webdata.url}`} passHref onClick={() => modules.handleLinkLoading(true)} scroll={true}>
      <div style={{ width: `164px` }}
        className={`hover:border-gray-200 border-transparent border-2 rounded-xl m-1 contrastCard relative`}>
        <div id={props.brand_id} style={{ overflow: `unset`, height: `164px`, width: `100%` }} className={`rounded-2xl flex items-center justify-center contrastParent ${!props.mobile && `relative`}`}>
          <CardImage {...{ urlThumb, imgalt: webdata.h1, hostname, cardWidth, imgTitle: webdata.title, bike: true, containt: `object-contain` }} /></div>
      </div>
    </Link>
  )
}
