'use client'
import parse from "html-react-parser"
import { memo, use, useContext, useMemo, useState } from 'react'
import { DropDownArrows } from '../../../basket/subcomponent/components/subcomponents/DropDownArrows'
import SimulatorOney from '../../../basket/subcomponent/components/subcomponents/oney/modal/SimulatorOney'
import { useOney } from "@/hooks/utils/useOney"
import { ToolsContext } from "@/context/tools/tools"

const OneyModal = props => {
  const {
    totalPrice, hostname
  } = props

  const [ openOney, setOpenOney ] = useState(false)
  const {
    refered, setRefered, payCodes, setPayCodes, businessCode, setBusinessCode
  } = useOney()
  const { colors } = hostname
  const { tools: { texts }} = useContext(ToolsContext)
  const payMethods = useMemo(() => texts?.cart_page.payMethods, [texts])
  const oneyActive = useMemo(() => {
    const active = totalPrice >= 90 && totalPrice <= 2500
    if (!active) {
      setOpenOney(false)
    }
    return active
  }, [totalPrice])

  return !!payMethods && (<>
    <div className="flex flex-col sm:flex-row items-baseline sm:items-center">
      <button style={{ "--oney": colors.oney }} className={`oney-btn ${oneyActive ? `cursor-pointer hover:underline` : `cursor-default`}`} onClick={() => oneyActive && setOpenOney(!openOney)}>
        <span className="text-left"> {parse(payMethods?.oney?.title)}</span>
        <DropDownArrows open={openOney} className={`${!oneyActive ? `hidden` : ``} text-neutral-500`} />
      </button>
      {!oneyActive && <small className="font-medium text-neutral-500 sm:mt-1 sm:ml-2">{(`(${parse(payMethods?.oney?.info[0])})`)}</small>}
    </div>
    <SimulatorOney {...{
      price: totalPrice,
      hostname,
      texts,
      businessCode,
      setBusinessCode,
      refered,
      setRefered,
      payCodes,
      setPayCodes,
      openOney,
      show: false,
      ubi: `prod`
    }} />
  </>
  )
}

export default memo(OneyModal)
