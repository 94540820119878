'use client'
import { PaypalMsg } from '@/app/(web)/_components/basket/subcomponent/components'
import { SizeModal } from '@/app/(web)/_components/products/subcomponents/modal'
import OneyModal from '@/app/(web)/_components/products/subcomponents/modal/OneyModal'
import { Receive } from '@/app/(web)/_components/products/subcomponents/Receive'
import { ShipCost } from '@/app/(web)/_components/products/subcomponents/ShipCost'
import { ShopAvailability } from '@/app/(web)/_components/products/subcomponents/ShopAvailability'
import { TWButton } from '@/components/tailwind/Button'
import { ErrorPill } from '@/components/tailwind/Errors'
import { fixTwoDecimal, getControlledSections } from '@/utils/functions'
import { CircularProgress } from '@mui/material'
import { memo, Suspense, useEffect, useRef, useState } from 'react'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'
import ProductPrice from './ProductPrice'
import VariantButton from './VariantButton'
export const experimental_ppr = true
const ProductBuyPanel = ({ hostname, globalTexts, product, pageTexts, price, variantError, selected, selectVariant, hasVariants, amount, currency, loading, addToCart, setAmount, sections }) => {
  const { attrVariant, variants, sizeGuide } = product
  const size = sizeGuide?.href ?? null
  const { colors, lang, mobile, stores, webPages } = hostname
  const { size_guide, add_to_cart, store_availability, check_stock } = pageTexts
  const [ open, setOpen ] = useState(false)
  const section = getControlledSections(webPages, `catalogue`)
  const [ visible, setVisible ] = useState(true)
  const ref = useRef(null)

  const handleAmount = ({ target }) => {
    if (target.value < 1 || isNaN(target.value)) {
      setAmount(1)
    } else if (target.value >= 99) {
      setAmount(99)
    } else {
      setAmount(target.value)
    }
  }

  const handleBtnsAmount = amnt => {
    if (amnt < 1 || isNaN(amnt)) {
      setAmount(1)
    } else if (amnt >= 99) {
      setAmount(99)
    } else {
      setAmount(amnt)
    }
  }

  const handleAnchor = name => {
    const targetElement = document.getElementById(name)
    if (targetElement) {
      targetElement.scrollIntoView({
        behavior: `smooth`,
        block: `start`,
      })
    }
  }

  useEffect(() => {
    if (mobile && variantError) {
      handleAnchor(`buy-panel`)
    }
  }, [variantError])

  const handleCheckError = () => {
    if (variantError) {
      handleAnchor(`buy-panel`)
    } else {
      addToCart()
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setVisible(entry.isIntersecting)
    }, { threshold: 0.1 })

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [])

  return <div id="buy-panel">
    <ProductPrice {...{ colors, globalTexts, pageTexts, price }} />
    {variants?.length > 1 && product.pack === null &&
      <div id="size-wrapper" className="my-3">
        <p className="text-base font-bold">{attrVariant?.name}</p>
        {!!size && <span className="flex items-center">
          <p >{size_guide.title}</p>
          <TWButton {...{ colors }} type="text" className="text-blue-500 ml-1" onClick={() => setOpen(true)}>
            {size_guide.button}
          </TWButton>
        </span>}
        <ErrorPill text={`${pageTexts.add_not_selected} ${attrVariant?.name.toLowerCase()}`} error={variantError} />
        <div id="variants-box" className="flex items-center overflow-x-auto">
          {variants.map((varelem, index) => (
            <VariantButton key={`var_${index}`} {...{
              variant: varelem, attributes: attrVariant?.attributes, lang, colors, varInfo: variants.find(varsVar => varsVar.variant_id === varelem.variant_id), selected, setSelected: selectVariant
            }}/>
          ))}
        </div>
      </div>
    }
    <Suspense>
      {hasVariants && <div className="mb-2" >
        <Receive {...{
          globalTexts, selected, amount, hostname
        }} />
        <ShipCost {...{
          pageTexts, item: variants, currency, hostname, totalPrice: price.total
        }} />
        {[`com`].includes(hostname.domain) && section.availability && <ShopAvailability {...{
          globalTexts, store_availability, selected, amount, mobile, stores, colors, check_stock, sections
        }} />}
      </div>}
    </Suspense>
    <div id="buy-buttons">
      <p>{globalTexts.amount}</p>
      <div className="flex flex-col tablet:flex-row items-start tablet:items-start">
        <span className="flex flex-row items-end tablet:items-center tablet:flex-col  m-1">
          <div className="rounded-full bg-neutral-200 text-black flex items-center justify-around px-4 py-2 tablet:mb-1 w-28">
            <button disabled={amount === 1} onClick={() => handleBtnsAmount(amount - 1)}><FiMinusCircle className="w-5 h-5"/></button>
            <input style={{ color: colors.secondary }} value={amount} onChange={handleAmount} className="bg-transparent min-w-0 text-center font-bold focus:outline-none active:outline-none"/>
            <button onClick={() => handleBtnsAmount(amount + 1)}><FiPlusCircle className="w-5 h-5"/></button>
          </div>
          {amount > 1 &&
              <p style={{ backgroundColor: colors.secondary, color: colors.white }} className="rounded-full px-3 ml-1 tablet:ml-0 text-base font-semibold p-0 bg-transparent text-center h-full">{`${fixTwoDecimal(price.total * (currency?.exchange ?? 1))} ${currency?.symbol}`}</p>
          }
        </span>
        <span ref={ref} className="flex flex-row items-center tablet:flex-col tablet:items-start m-1">
          <TWButton {...{ colors }} className="w-full sm:w-auto px-5 tablet:px-8 py-3"
            onClick={() => addToCart()} disabled={loading} >
            { loading &&
              <CircularProgress style={{ color: colors.main, padding: 0, marginRight: `8px` }} size={17}/>}
            {add_to_cart}
          </TWButton>
        </span>
      </div>
    </div>
    { !visible && mobile && <div className="tablet:hidden right-0 fixed bottom-0 w-full z-[100]">
      <TWButton {...{ colors }} onClick={() => handleCheckError()} disabled={loading} className="rounded-none h-11 uppercase w-full">
        { loading && <CircularProgress style={{ color: colors.main, padding: 0, marginRight: `8px` }} size={17}/>}
        {add_to_cart}
      </TWButton>
    </div>
    }
    {hostname.arrPaymethodsTypes.includes(4) && <PaypalMsg {...{ price }}/>}
    {hostname.arrPaymethodsTypes.includes(7) && <OneyModal {...{ totalPrice: price.total, hostname }} />}
    {!!size && <SizeModal {...{ colors, open, setOpen, path: `/size-guides/${size}` }} />}
  </div>
}

export default memo(ProductBuyPanel)
