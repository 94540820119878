import {
  Backdrop, Box, CardMedia, Fade, Modal
} from "@mui/material"

export const SizeModal = props => {
  const {
    open, setOpen, colors, path
  } = props
  const style = {
    position: `absolute`,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: `90vw`,
    height: `90vh`,
    border: `0px solid ${colors.backGrey}`,
    borderRadius: 2.5,
    backgroundColor: colors.white,
    overflowY: `auto`,
    padding: 0,
  }
  return (
    <Modal
      aria-labelledby="modal_title"
      aria-describedby="modal_desc"
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 150,
      }}
      sx={{ zIndex: 1601 }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <CardMedia
            component="iframe"
            src={window?.location.origin + path}
            sx={{
              width: `100%`,
              height: `100%`,
              borderRadius: 2.5,
              border: 0,
            }}
          />
        </Box>
      </Fade>
    </Modal>
  )
}
