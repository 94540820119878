import ImagesCarousel from '@/app/(web)/_components/carousel/ImagesCarousel'

const ImagesModal = ({
  hostname, images, openImgModal, setOpenImgModal
}) => {
  return (
    <div className={`modal ${openImgModal ? `flex flex-col items-center justify-center` : ``} `} onClick={e => {
      setOpenImgModal(false)
    }}>
      <div className="modal-content">
        <ImagesCarousel id="home-banners-carousel" {...{ hostname, images }} imageIndicators={true} modal={true}/>
      </div>
    </div>
  )
}

export default ImagesModal
