'use client'
import { contructMicroBread } from "@/utils/functions/microdata"
import { constructScript, removeScript } from "@/utils/functions/scripts"
import { useEffect } from "react"

export const MicroBreadCrumbs = ({ breadCrumbs, parent, webdata, hostname, ...props }) => {
  const breads = breadCrumbs.length > 0 ? breadCrumbs : [webdata.h1]
  const urls = breadCrumbs && parent?.url ? [`/${parent?.url}`] : []
  const microbread = JSON.stringify({
    "@context": `https://schema.org`,
    "@type": `BreadcrumbList`,
    itemListElement: contructMicroBread(breads, urls, hostname),
  })

  useEffect(() => {
    const name = `micro-breadcrumbs`
    constructScript(`script`, name, microbread)

    return () => {
      removeScript(name)
    }
  }, [microbread])
}
