import { cleanPath } from ".."
import { conditionProduct } from "../products"

export const contructMicroBread = (breadcrumbs, links, hostname) => {
  const { business, domain, texts } = hostname
  const { thumb, address, alias, webUrl } = business
  const MicroBread = []
  breadcrumbs?.map((crumb, idx) => MicroBread.push({
    "@type": `ListItem`,
    position: idx + 1,
    name: crumb?.h1 || crumb,
    item: crumb?.path || links[idx] ? `${webUrl}${domain}${cleanPath(crumb?.path) || cleanPath(links[idx])}` : undefined,
  }))
  return MicroBread
}

export const simpleMicroProduct = (host, products) => {
  const MicroProduct = []
  products?.map(product => MicroProduct.push({
    "@type": `Product`,
    name: `${product?.title}`,
    url: cleanPath(`${host}${product?.url}`),
    offers: {
      "@type": `http://schema.org/Offer`,
      price: `${product?.cost}`,
      priceCurrency: `EUR`,
      itemCondition: conditionProduct(product, false, true),
      url: cleanPath(`${host}${product?.url}`),
    },
  }))
  return MicroProduct
}
