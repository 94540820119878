import { CatalogueContext } from "@/context"
import { types } from "@/types"
import { constructPath } from "@/utils/functions/catalogue"
import { constants } from "@/utils/global"
import PagNext from '@mui/material/Pagination'
import { useRouter } from "next/navigation"
import queryString from "query-string"
import { useContext } from "react"

export const Pagination = props => {
  const router = useRouter()
  const { page, count, nextpage } = props
  const { info, handleLoading } = useContext(CatalogueContext)
  const { pathFilter, cleanPath } = info

  const handleChangePath = async(e, nxPag) => {
    const params = await queryString.parse(globalThis.window.location.search, { arrayFormat: `bracket-separator`, arrayFormatSeparator: `|` })
    const { p, ...tempParams } = params
    const finalParams = {
      ...params,
      p: nxPag,
    }
    const obj = {
      ...constants.OBJ_FILTER,
      type: types.catalog.page,
      parent: types.catalog.get,
      pathParams: [ `GET`, `p`, nxPag ],
      page: nxPag,
    }
    const newFilter = await pathFilter.find(x => x.type === types.catalog.page) ?
      await pathFilter.filter(x => x.type !== types.catalog.page) :
      [ pathFilter.filter(x => x.type !== types.catalog.page), obj ].flat()
    await handleLoading(true)
    await constructPath(
      finalParams, newFilter, cleanPath, router, true
    )
    await nextpage(e, nxPag)
  }

  return (
    <div className="m-auto align-baseline justify-center px-[4px] sm:py-[40px] py-[4px]">
      <div className="flex flex-col w-full">
        <PagNext id="pag-div" onChange={handleChangePath} {...{ count, page }} />
      </div>
    </div>
  )
}
