/* eslint-disable no-param-reassign */
'use client'
import { stockShops } from "@/utils/functions/products"
import { useEffect, useState } from "react"
import { ShopsModal } from "./modal"

export const ShopAvailability = props => {
  const { selected, amount } = props
  const { variant_id } = selected
  const [ stocks, setStocks ] = useState({})
  const [ loading, setLoading ] = useState(false)
  const [ show, setShow ] = useState(false)

  const getShopsData = async() => {
    let shops = {}
    if (selected?.variant_id) {
      await setLoading(true)
      shops = await stockShops({
        variantId: selected?.variant_id,
        amount,
      })
      if (shops.total) {
        await setLoading(false)
      }
    }
    const filteredStock = await Object.entries(shops).filter(([ name, value ]) => name !== `total` && value !== `No disponible`)
    if (filteredStock.length) {
      const stock = await filteredStock.reduce((acc, [ shop, value ]) => {
        acc = { ...acc, [shop]: value }
        return acc
      }, {})

      await setStocks(stock)
      await setShow(true)
    } else {
      await setStocks({})
      await setShow(false)
    }
  }

  useEffect(() => {
    getShopsData()
  }, [ variant_id, amount ])

  return selected?.variant_id && show && <ShopsModal {...props} {...{ loading, stocks: { ...stocks, total: null }}} />
}
