'use client'
import { useRelations } from "@/hooks/relations/useRelations"
import Relations from "./Relations"
const ProductRelations = ({ ...props }) => {
  'use memo'
  const {
    product, hostname
  } = props
  const relations = useRelations({ hostname, product })

  return <Relations {...props} {...{ prodList: relations }}/>
}

export default ProductRelations
