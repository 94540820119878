/* eslint-disable no-mixed-operators */
'use client'
import { CatalogueContext } from "@/context"
import { LoadingContext } from "@/context/tools/loading"
import { useFilter, useFilterPops } from "@/hooks/filter"
import useScrollTop from "@/hooks/utils/useScrollTop"
import { types } from "@/types"
import { fixTwoDecimal } from "@/utils/functions"
import { constants } from "@/utils/global"
import { useContext, useEffect, useMemo, useState } from "react"
import Catalogue from "./Catalogue"
import { FilterMenu } from "./FilterMenu"
import { Pagination } from "./Pagination"

export const CategoryGrid = props => {
  useScrollTop()
  const { data, category, meta, search, hostname, pageTexts } = props
  const { catalogue, prodList } = data
  const { maxPrice, page: currentPage } = prodList
  const { info, getInit, handlePathFilter, handleLoading } = useContext(CatalogueContext)
  const { modules } = useContext(LoadingContext)
  const { pathFilter, limit, loading } = info
  const { result, categories } = prodList
  const { mobile, texts, colors } = hostname
  const { filters, filPath, setParamsFilter } = useFilter({ catalogue, brands: catalogue.brands, lang: hostname.lang.toLowerCase() })
  const [ page, setPage ] = useState(currentPage)
  const count = useMemo(() => Math.ceil(fixTwoDecimal(result / constants.CATALOGO_LIMIT, 1)), [result])
  const realPrice = constants.PRICE_RANGE.max
  const realMaxPrice = useMemo(() => maxPrice > 0 &&
  pathFilter.filter(x => ![ types.catalog.order.order, types.catalog.priceRange ].includes(x.type)).length > 0 ?
    maxPrice : realPrice, [ maxPrice, realPrice ])
  const nextpage = (event, evpage) => {
    setPage(evpage)
  }
  const handleRemove = async() => {
    await handlePathFilter(pathFilter.filter(x => x.type === types.catalog.meta))
    setPage(1)
  }

  const handleInit = async() => {
    if ((prodList.filters?.length > 0 || filters?.length > 0 || category) && (info.id === null && info.category === null || info.id !== catalogue.info?.id) && !meta) {
      await getInit({
        id: catalogue?.info?.id,
        cleanPath: !search ? catalogue.path : `/search/`,
        category,
        info: catalogue.info || catalogue.webdata,
        pathFilter: filPath,
        filters,
        loading: false
      })
    }
  }

  const { execFilterProps } = useFilterPops(
    setParamsFilter, handlePathFilter, catalogue.brands, hostname.lang.toLowerCase()
  )
  useEffect(() => {
    handleInit()
    execFilterProps(
      setParamsFilter, handlePathFilter, catalogue.brands, hostname.lang.toLowerCase(), catalogue.filters, catalogue.specialList
    )
  }, [])

  useEffect(() => {
    handleLoading(false)
    modules.handleLinkLoading(false)
  }, [catalogue])

  return (
    <div className="grid grid-cols-1">
      <FilterMenu
        {...{
          pageTexts,
          hostname,
          handleRemove,
          realMaxPrice: realMaxPrice + (catalogue.info?.id === 227 ? 20000 : 0),
          chipcategories: search || !category || category && catalogue.info.id === 234 ? catalogue.chips.map(x => {
            return categories?.includes(x.info.id) && x
          }).filter(x => x !== false) : catalogue.chips,
          category,
          result,
          web: texts?.web,
          filters: catalogue.filters,
          fields: {},
          brandAttr: catalogue.brands,
          texts,
          meta: !search ? category && catalogue.info.id === 234 : false,
          motovo: category && catalogue.info.id === 227,
          catalogue,
          prodList,
          search,
          specialList: catalogue.specialList,
          colors,
          loading
        }}
      />
      <Catalogue {...props} {...{ motovo: category && catalogue?.info?.id === 227, origin: `${category ? `category` : `brand`}-${catalogue?.info?.id || catalogue?.brand_id}`, prodList }} />
      {result > limit && <Pagination {...{ mobile, nextpage, page, count }} />}
    </div>
  )
}
