'use client'
import { getProductCarts, getProductVisits } from "@/app/elastic/product"
import { useEffect, useState } from "react"

export const useVisits = props => {
  const { product, pageTexts, hostname, sections } = props
  const { business, platformId, colors } = hostname
  const [ visits, setVisits ] = useState(null)
  const getVisits = async() => {
    if (sections.cartVisits) {
      let cartVisits = await getProductCarts({ product_id: product.product_id })
      let source = null
      if (cartVisits <= 0) {
        cartVisits = await getProductVisits({ prodId: product.id, platformId, abbrElastic: business.abbrElastic }, null)
        source = cartVisits > 0 ? `product` : null
      } else {
        source = `cart`
      }
      const selVisit = cartVisits === null || source === null ? source : {
        text: pageTexts[`visits_${source}`].replace(`{visit}`, cartVisits),
        color: source === `product` ? colors.product.visits : colors.product.carts
      }

      !!cartVisits && await setVisits(selVisit)
    }
  }
  useEffect(() => {
    getVisits()
  }, [])
  return visits
}
