import TWLabel from '@/components/tailwind/Label'
import { useVisits } from '@/hooks/utils/useVisits'

const ProductLabel = ({ product, hostname, pageTexts, sections, ...props }) => {
  const { colors } = hostname
  const cartVisits = useVisits({ product, hostname, pageTexts, sections })

  return cartVisits &&
    <TWLabel {...{ colors }} bgColor={cartVisits.color} rounded text>
      {cartVisits.text}
    </TWLabel>
}

export default ProductLabel
