'use client'
import { dateToReceive } from "@/utils/functions/products"
import { useEffect, useState } from "react"

export const Receive = props => {
  const {
    globalTexts, selected, hostname, amount
  } = props
  const [ dateReceive, setDateReceive ] = useState()
  const [ load, setLoad ] = useState(true)
  const { ipInfo, colors } = hostname

  const getDateReceive = async() => {
    !dateReceive && await setLoad(true)
    const result = await dateToReceive({
      variantId: selected?.variant_id,
      amount,
      months: globalTexts.months,
      userId: null,

      // country: ipInfo?.code,
      country: `ES`,
      platform: hostname?.id,
    })
    const date = await result
    if (date.day) {
      await setLoad(false)
    }
    await setDateReceive(`${date.day} ${date.month} ${date.annio}`)
  }

  useEffect(() => {
    getDateReceive()
  }, [amount])

  return !load && <div className="mt-1 flex" >
    <p className="rounded-full px-3 py-[2px]" style={{ backgroundColor: colors.main, color: colors.white }}>
      {globalTexts.receive_before + dateReceive}
    </p>
  </div>
}
