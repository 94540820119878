'use client'
import ImagesCarousel from '@/app/(web)/_components/carousel/ImagesCarousel'
import ProductRelateds from '@/app/(web)/_components/products/ProductRelateds'
import ProductRelations from '@/app/(web)/_components/products/ProductRelations'
import { TWDivider } from '@/components/tailwind/Divider'
import { CartContext } from '@/context'
import { useCart } from '@/hooks/cart/useCart'
import useScrollTop from '@/hooks/utils/useScrollTop'
import { types } from '@/types'
import { getControlledSections, sorter } from '@/utils/functions'
import { editBasketWeb } from '@/utils/functions/cart'
import { ga, gtagAddToCart } from '@/utils/functions/google'
import { constants } from '@/utils/global'
import IconsSVG from "@/utils/icons/IconsSVG"
import Rating from '@mui/material/Rating'
import Link from 'next/link'
import { Suspense, use, useContext, useEffect, useMemo, useRef, useState } from 'react'
import BikeContactPanel from '../bike/BikeContactPanel'
import BikeLabel from '../bike/BikeLabel'
import { Specifications } from '../bike/Specifications'
import RatingSummary from '../ratings/RatingSummary'
import ImagesModal from './subcomponents/ImagesModal'
import ProductBuyPanel from './subcomponents/ProductBuyPanel'
import ProductDescription from './subcomponents/ProductDescription'
import ProductLabel from './subcomponents/ProductLabel'
import { LoadingContext } from '@/context/tools/loading'
import { useEffectOnce } from '@/hooks/utils/useEffectOnce'
import { ToolsContext } from '@/context/tools/tools'
export const experimental_ppr = true
const { RATING, VARIANT_AVAILABLE } = constants

const ProductPage = ({ ...props }) => {
  useScrollTop()
  const { addRemoveItem } = useCart()
  const { cart, toggleCartDrawer } = useContext(CartContext)
  const { currency } = cart
  const { hostname, product, bike = false } = props
  const { colors, ipInfo, login, webPages } = hostname
  const { tools: { texts }} = useContext(ToolsContext)
  const globalTexts = useMemo(() => texts.generic, [texts])
  const prodTexts = useMemo(() => texts.product_page, [texts])
  const bikes = useMemo(() => texts.bike_page, [texts])
  const ratings = useMemo(() => texts.ratings, [texts])
  const privacyTexts = useMemo(() => texts.privacy_policy, [texts])
  const { webdata, brand, id, reviews, price, images, relateds, variants, pack, breadcrumbs } = product

  // const { relateds: textRelateds, product_description, relateds_carousel, reference } = useMemo(() => prodTexts, [prodTexts])
  const descRefTop = useRef(null)
  const descRefBottom = useRef(null)
  const [ openImgModal, setOpenImgModal ] = useState(false)
  const [ showDescription, setShowDescription ] = useState(false)
  const [ hideInfoBtn, setHideInfoBtn ] = useState(false)
  const sections = getControlledSections(webPages, `catalogue`)
  // eslint-disable-next-line no-extra-parens
  const varMemo = useMemo(() => variants.find(x => (x.price?.cost === price?.cost && !x.sale_ko) || !x.sale_ko), [variants])
  const [ selected, setSelected ] = useState(variants?.length > 1 && !product.lote ? sections?.selectVariant ? varMemo : null : variants ? variants[0] : null)
  const [ amount, setAmount ] = useState(1)
  const [ totalPrice, setTotalPrice ] = useState({ ...price, total: price?.cost ?? pack.cost })
  const [ variantError, setVariantError ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const hasVariants = useMemo(() => variants?.length > 0 && selected?.variant_id && VARIANT_AVAILABLE.includes(selected?.external?.diponibility), [selected])
  const classDescription = `${showDescription || hideInfoBtn ? `h-auto` : `max-h-36 desc-shadow`} my-2 mx-4 text-base`
  const classIconBtn = `size-2 mr-1`

  const { modules } = useContext(LoadingContext)
  useEffectOnce(() => {
    modules.handleLinkLoading(false)
  })
  useEffect(() => {
    setHideInfoBtn(!(descRefTop.current?.clientHeight > 125 || descRefBottom.current?.clientHeight > 125))
  }, [ descRefTop.current, descRefBottom.current ])

  useEffect(() => {
    if (product.lote) {
      setTotalPrice(previousTotal => ({ ...previousTotal,
        pvp: parseFloat(pack.pvp ?? selected?.price?.pvp ?? price?.pvp),
        cost: parseFloat(pack.cost ?? price?.cost ?? selected?.price?.cost),
        discount: parseFloat(pack.discount ?? price?.discount ?? selected?.price?.discount),
        total: parseFloat(pack.cost ?? price?.cost ?? selected?.price?.cost) * amount }))
    } else {
      setTotalPrice(previousTotal => ({ ...previousTotal,
        pvp: parseFloat(selected?.price?.pvp ?? varMemo?.price?.pvp ?? pack?.pvp),
        cost: parseFloat(selected?.price?.cost ?? varMemo?.price?.cost ?? pack?.cost),
        discount: parseFloat(selected?.price?.discount ?? varMemo?.price?.discount ?? pack?.discount),
        total: parseFloat(selected?.price?.cost ?? varMemo?.price?.cost ?? pack?.cost) * amount }))
    }
  }, [ amount, selected ])

  const selectVariant = selVarId => {
    if (selected?.variant_id === selVarId) {
      setSelected(null)
    } else {
      const tmpSel = variants?.find(varsVar => varsVar.variant_id === selVarId)
      setSelected(tmpSel)
      setVariantError(false)
    }
  }

  const handleBuy = async selVars => {
    await setLoading(true)
    try {
      const result = await editBasketWeb(
        cart, product, selVars, amount, login, hostname, ipInfo
      )

      pack === null && await variants.forEach(item => {
        if (item.price.cost <= 0) {
          throw new Error(`Invalid price`)
        }
      })
      const response = await addRemoveItem({ data: result, msg: false, codeLang: hostname.lang.toLowerCase() })
      const GAddToCart = await gtagAddToCart(
        product,
        variants,
        amount,
        result.lines.map(item => item.total),
        breadcrumbs,
        hostname
      )

      // TODO: revisar
      await ga.event({ action: types.gtag.eventType.addToCart, params: GAddToCart })
      await toggleCartDrawer(true)
    } catch (error) {
      console.info(`error`)
    }
    await setLoading(false)
  }

  const addToCart = () => {
    if (selected || !!product.pack) {
      handleBuy(selected ?? variants)
    } else {
      setVariantError(!selected?.variant_id)
    }
  }

  return !!globalTexts &&
         (
           <div id="product-page" className="grid grid-cols-1 1200:grid-cols-2 tablet:px-5 py-2">
             <div id="images-description" className="tablet:mr-8">
               <div className="relative">
                 <ImagesCarousel id="home-banners-carousel" {...{ hostname, images, setOpenImgModal }}
                   imageIndicators={true}
                   product={true}/>
                 { bike ?
                   <BikeLabel {...{ hostname, isPro: product.professional }} /> :
                   <ProductLabel {...{ product, hostname, pageTexts: { ...prodTexts, bikes, ratings, privacyTexts }, sections }} />
                 }
               </div>
               <div className="hidden 1200:block">
                 {(bike || sections.description) &&
            <ProductDescription {...{
              globalTexts, product_description: prodTexts.product_description, webdata, classDescription, classIconBtn, showDescription, setShowDescription, hideInfoBtn
            }} ref={descRefTop}/>
                 }
               </div>
             </div>
             <div id="panel-buy" className="px-3">
               <div id="brand-reference" className="flex items-center">
                 {!bike && !!sections?.brand && <Link href={brand.path} className={brand.name ? `block` : `hidden` }>
                   <button style={{
                     color: colors.tild
                   }} className="btn-pill">
                     <p className="font-bold">{brand.webdata.h1}</p>
                   </button>
                 </Link>}
                 <p className={`${brand.name ? `px-3` : ``} capitalize font-bold text-neutral-500`}>{`${prodTexts.reference}: ${id}`}</p>
               </div>
               <div id="product-title" className="py-3">
                 {bike ? <>
                   <h1 className="text-[34px] tablet:text-[40px] font-[700] uppercase">{webdata.prefix}</h1>
                   <h2 className="text-[24px] tablet:text-[32px] font-[700] capitalize" >{webdata.h1}</h2>
                 </> :
                   <h1 className="pb-1">{ `${webdata.prefix ?? ``}  ${webdata.h1}` }</h1>
                 }
                 <h2 className="text-[16px] font-[500]">{webdata.h2}</h2>
               </div>
               {!bike && !!sections?.ratings && <RatingSummary {...{ reviews, ratings }} />}
               {!bike && <TWDivider/>}
               {!bike && !!sections?.ProductBuyPanel &&
        <ProductBuyPanel {...{
          hostname, globalTexts, product,
          pageTexts: { ...prodTexts, bikes, ratings, privacyTexts },
          price: totalPrice, variantError, selected, selectVariant, hasVariants, amount, currency, loading, addToCart, setAmount, sections
        }}/> }
               {(bike || !!sections?.BikeContactPanel) && <BikeContactPanel {...{ product, currency, globalTexts, pageTexts: { ...prodTexts, bikes, ratings, privacyTexts }, hostname, texts, bike }}/>}
               <TWDivider/>
               {(bike || !!sections?.specifications) &&
        <div className="hidden 1200:block">
          <Specifications {...{ product, bike, hostname, bikes }}/>
        </div>
               }
               {/* {!bike && !!relateds?.canonical.length && <Relations key={`relations-carousel-canonical`} {...{ hostname, prodList: relateds?.canonical, origin: `canonical`, colors, number: 0, position: `top`, small: true }}
        />} */}
               {!bike && !!relateds?.canonical.length && <ProductRelateds key={`relations-carousel-canonical`} {...props} {...{ texts, images, type: `canonical`, origin: `canonical`, image: null, relateds: [], titleText: ``, position: `top`, ischild: true, show: false }} />}
             </div>
             <div className="block 1200:hidden">
               {(bike || !!sections?.description) &&
      <ProductDescription {...{
        globalTexts, product_description: prodTexts.product_description, webdata, classDescription, classIconBtn, showDescription, setShowDescription, hideInfoBtn
      }} ref={descRefBottom}/>}
               {(bike || !!sections?.specifications) && <Specifications {...{ product, bike, hostname, bikes }}/>}
             </div>
             {!bike && !!sections?.relateds && relateds !== null && !!Object.keys(prodTexts.relateds)?.length &&
      Object.entries(prodTexts.relateds).map(([ key, titleText ], i) => !!relateds[key].length && (
        <div key={`${key}_${i}`} id={`product-relateds-${key}`} className="1200:col-span-2">
          <ProductRelateds {...props} {...{ texts, images, type: key, origin: key, image: sorter(product.images, `sequence`, `desc`)[0], relateds: [], titleText, position: titleText.toLowerCase().replaceAll(` `, `_`), ischild: true }} />
        </div>
      ))}
             {!bike && !!sections?.relations && <div id={`product-relations`} className="1200:col-span-2">
               <Suspense>
                 <ProductRelations key={`relations-carousel-other-interest`} {...{ product, hostname, carouselTitle: prodTexts.relateds_carousel.other_interest, viewbuttons: true, prodList: [], origin: `other`, colors, number: 0, position: `bottom` }}
                 />
               </Suspense>
             </div> }
             {!bike && !!sections?.ratings && <div id={RATING.name} className={reviews.count ? `block` : `hidden`}>
               <div id="review-title" className="flex items-start flex-col 650:items-center 650:flex-row">
                 <span className="flex items-center">
                   <IconsSVG name={RATING.name} path={RATING.path} />
                   <p className="text-xl capitalize font-semibold mr-2">{ratings.ratings}</p>
                 </span>
                 <RatingSummary {...{ reviews, ratings }} />
               </div>
               <Suspense>
                 <div id="review-body" className="ml-8 mt-2">
                   {reviews?.ratings?.map((rating, i) => (
                     <div key={`rating_${i}`} className="px-1">
                       <p style={{ color: colors.main }} className="text-lg font-semibold">{rating.client.name}</p>
                       <span className="flex items-center">
                         <Rating value={parseFloat(rating.score) ?? 0} {...{ precision: 0.5, readOnly: true }} size="small" className="mr-2"/>
                         <p className="text-neutral-400 text-xs">{new Date(rating.create_date).toLocaleDateString()}</p>
                       </span>
                       <p className="text-neutral-600 text-sm font-semibold pt-1">{rating.title_comment}</p>
                       <p className="text-neutral-500 text-sm">{rating.comment}</p>
                       { i < reviews.ratings.length - 1 && <TWDivider/>}
                     </div>
                   ))}
                 </div>
               </Suspense>
             </div> }
             <ImagesModal {...{ hostname, images, openImgModal, setOpenImgModal }}/>
           </div>
         )
}

export default ProductPage
