import { fixDescription } from '@/helpers/textsHelper'
import React, { forwardRef } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa'

const ProductDescription = forwardRef(({
  product_description, webdata, classDescription, classIconBtn, showDescription, setShowDescription, hideInfoBtn, globalTexts
}, ref) => {
  return <span className="p-2">
    <p className={`text-xl font-bold`}>
      {product_description}
    </p>
    <div ref={ref} id="description" className={classDescription}>
      <h3 className="mb-1 leading-normal text-[14px] font-[500]">{webdata.h3 ?? ``}</h3>
      {fixDescription(webdata.description)}
    </div>
    {!hideInfoBtn && <button onClick={() => setShowDescription(!showDescription)} className="btn-pill text-neutral-600">
      {showDescription ? <FaMinus className={classIconBtn} /> : <FaPlus className={classIconBtn}/>}
      {globalTexts.info}
    </button>}
  </span>
})

export default ProductDescription
