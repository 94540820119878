'use client'
import { getConstructRelateds } from "@/app/elastic/product"
import { useEffect, useState } from "react"

export const useRelateds = props => {
  const [ optRelateds, setOptRelateds ] = useState([])
  const { hostname, relateds, product_id } = props
  const { platformId, langId: languageId, business: { abbrElastic }, login } = hostname
  const execute = async() => {
    const tmpResult = await getConstructRelateds({ platformId, languageId, prodId: product_id, abbrElastic, clientId: login?.user.clientId || hostname?.clientId }, relateds ?? [], null)
    await setOptRelateds(tmpResult)
  }

  useEffect(() => {
    execute(props)
  }, [])

  return optRelateds
}
