import React from 'react'
import { BikePrice } from './BikePrice'
import { BikeContact } from './BikeContact'
import ProductPrice from '../product/subcomponents/ProductPrice'

const BikeContactPanel = ({ product, globalTexts, currency, pageTexts, hostname, texts, bike }) => {
  const { colors } = hostname
  return (
    <div>
      {bike ? <BikePrice {...{ product, currency, colors }} /> :
        <ProductPrice {...{ colors, globalTexts, pageTexts, price: product.price }} /> }
      <BikeContact {...{ pageTexts, product, hostname, bike }}/>
    </div>
  )
}

export default BikeContactPanel
