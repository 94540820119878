'use client'

import { useEffectOnce } from "@/hooks/utils/useEffectOnce"
import { ga } from "@/utils/functions/google"
import { useEffect } from "react"

export const GTAGViewItemList = props => {
  const { dataProd, event, page } = props
  useEffect(() => {
    ga.event({
      action: event,
      params: dataProd
    })
    ga.event({
      action: `page_view`,
      params: {
        ecomm_pagetype: page,
        ecomm_prodid: dataProd.arrProdID
      }
    })
  }, [dataProd])
}

export const GoogleGa4Events = props => {
  const { dataProd, event } = props
  useEffectOnce(() => {
    ga.event({
      action: event,
      params: dataProd
    })
  }, [])
}
