'use client'
import { useState } from "react"

export const useOney = () => {
  const [ refered, setRefered ] = useState(1)
  const [ payCodes, setPayCodes ] = useState({
    use: true,
    pays: [],
    tax: []
  })
  const [ businessCode, setBusinessCode ] = useState()

  return {
    refered,
    setRefered,
    payCodes,
    setPayCodes,
    businessCode,
    setBusinessCode
  }
}
