import parse from 'html-react-parser'

export const fixDescription = text => {
  let tmp = ``
  if (text) {
    tmp = text.replaceAll(`&lt;`, `<`)
    tmp = tmp.replaceAll(`&gt;`, `>`)
    tmp = tmp.replaceAll(`&nbsp;`, ` `)
    tmp = tmp.replaceAll(`&nbsp;`, ` `)
  }
  return parse(tmp)
}
