import { BikeCard, ProductCard } from "@/app/(web)/_components/card"
import { handleKeyRandom } from "@/utils/functions"

const Catalogue = props => {
  'use memo'
  const { motovo, origin, prodList, hostname, pageTexts } = props
  const { mobile, domain, events, colors } = hostname
  const { catalogue, labels } = prodList
  return (
    <div className="flex flex-wrap min-h-5 justify-evenly py-[40px]">
      {catalogue?.length > 0 &&
        catalogue?.map((elem, idx) => {
          return !motovo ?
            <ProductCard
              key={handleKeyRandom(elem.url, idx)}
              {...{
                element: elem,
                mobile,
                domain,
                events,
                product: elem,
                login: null,
                catalogue: true,
                origin,
                hostname,
                colors,
                show: true,
                small: false,
                labels
              }}
            /> :
            <BikeCard
              key={handleKeyRandom(elem.url, idx)}
              {...{
                element: elem,
                mobile,
                origin,
                domain,
                hostname,
                colors,
                small: false,
                pageTexts
              }}
            />
        })
      }
    </div>
  )
}

export default Catalogue
