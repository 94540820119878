import { TWButton } from "@/components/tailwind/Button"
import { handleKeyRandom } from "@/utils/functions"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { useId } from "react"
export const BoxCategories = ({ ...props }) => {
  const { chipcategories, category, colors } = props
  const keyId = useId()
  const path = usePathname()
  return (
    <div className="flex tablet:flex-wrap overflow-x-auto">
      {chipcategories?.map((x, i) => (
        <div className="mr-1 mb-1" key={handleKeyRandom(keyId, i)}>
          {category ? (
            <Link prefetch={false} passHref href={{ pathname: x.path }} style={{ cursor: `pointer`, textDecoration: `none` }}>
              <TWButton
                className={`bg-neutral-500 px-[10px] border-0 py-[1px] whitespace-nowrap font-semibold text-[14px] hover: hover:bg-neutral-400`}
                {...{ colors }}
                color={colors.white}
                type="outlined"
              >
                {x.info.h1}
              </TWButton>
            </Link>
          ) : (
            <Link prefetch={false} passHref href={{ pathname: x.path + path }} style={{ cursor: `pointer`, textDecoration: `none` }}>
              <TWButton
                className={`bg-neutral-500 px-[10px] border-0 py-[1px] whitespace-nowrap font-semibold text-[14px] hover: hover:bg-neutral-400`}
                {...{ colors }}
                color={colors.white}
                type="outlined"
              >
                {x.info.h1}
              </TWButton>
            </Link>
          )}
        </div>
      ))}
    </div>
  )
}
