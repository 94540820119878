/* eslint-disable no-param-reassign */
import { handleKeyRandom, sorter } from '@/utils/functions'
import { useId } from 'react'

export const Specifications = props => {
  const { product, bike, hostname, bikes } = props
  const { colors } = hostname
  const keyId = useId()
  const fillSpecs = () => {
    if (bike) {
      const grouped = product.attrs?.reduce((acc, obj) => {
        const { name, value } = obj
        if (!acc[name]) {
          acc[name] = value
        } else {
          acc[name] += `, ${value}`
        }
        return acc
      }, {})

      return Object.entries(grouped).map(([ name, value ]) => ({ name, attrs: value }))
    }
    return sorter(product?.filters?.map(x => ({
      name: x.translations[hostname.lang.toLowerCase()],
      sequence: x.sequence,
      attrs: x.attributes.map(y => product.attr.includes(y.id) && JSON.parse(y.translations)[hostname.lang.toLowerCase()]).filter(y => y !== false).
        join(`, `)
    })), `sequence`, `desc`)
  }
  const spc = fillSpecs()
  return (
    <>
      <div className="text-[20px] font-[700] capitalize mb-4">
        {spc?.length > 0 && bikes.specs.title }
      </div>
      <table className="shadow" style={{ border: `1px solid ${colors.lightGrey}` }}>
        <tbody>
          {spc?.map((x, i) => (
            <tr key={handleKeyRandom(keyId, i)}>
              <td style={{ backgroundColor: i % 2 === 0 ? colors.lightGrey : colors.white }} className="min-w-[50px] whitespace-nowrap text-[14px] text-right font-bold capitalize p-4">
                {x?.name}
              </td>
              <td style={{ backgroundColor: i % 2 === 0 ? colors.lightGrey : colors.white }} className="w-full text-[14px] capitalize p-4">
                {x?.attrs ?? ``}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}
