'use client'
import { getProdRelations } from "@/app/elastic/relations"
import { useEffect, useState } from "react"

export const useRelations = props => {
  const [ relations, setRelations ] = useState([])
  const { hostname, product } = props
  const execute = async() => {
    const prodRelationsFetch = await getProdRelations(
      hostname, product, true, true
    )
    await setRelations(prodRelationsFetch)
  }

  useEffect(() => {
    execute(props)
  }, [])

  return relations
}
