'use client'
import { useProductImage } from "@/hooks/product/useProductImage"
import { cleanPath, fixTwoDecimal, getTomorrow } from "@/utils/functions"
import { constructScript, removeScript } from "@/utils/functions/scripts"
import { simpleMicroProduct } from "@/utils/functions/microdata"
import { conditionProduct } from "@/utils/functions/products"
import { useEffect, useMemo, useState } from "react"

export const MicroProduct = ({ product, brand, canonicals, related, category, reviews, hostname, variant, ...props }) => {
  const { business, domain } = hostname
  const ratingValue = product?.reviews?.score ? Math.round(product?.reviews?.score) : 1
  const reviewCount = product?.reviews?.ratings?.length ?? 0
  const [imageUrl] = useProductImage({ image: product?.images[0].image, domain, thumb: business?.thumb })

  const prod = useMemo(() => {
    return JSON.stringify([
      {
        "@context": `http://schema.org`,
        "@type": `Product`,
        productID: `${product?.id}`,
        name: `${JSON.parse(product?.name)}`,
        typeOfGood: `product`,
        color: product.colors.color,
        description: `${product?.webdata?.h2}`,
        sku: `${product?.id}`,
        url: cleanPath(`${business.webUrl}${domain}${product?.webdata?.url}`),
        image: imageUrl,
        brand: {
          "@type": `Brand`,
          name: brand.name ?? ``,
        },
        model: `${product?.webdata?.h1}`,
        offers: {
          "@type": `http://schema.org/Offer`,
          price: `${fixTwoDecimal(product?.price?.cost || product?.pack?.cost)}`,
          priceCurrency: `EUR`,
          itemCondition: conditionProduct(product),
          availability: `http://schema.org/${product.variant?.filter(x => x.external.diponibility !== 3).length > 0 ? `InStock` : `OutOfStock`}`,
          category: `${category}`,
          priceValidUntil: `${getTomorrow()}`,
          url: cleanPath(`${business.webUrl}${domain}${product?.webdata?.url}`),
        },
        review: product?.reviews?.ratings.map((rate, idx) => ({
          "@type": `Review`,
          datePublished: rate.create_date,
          reviewBody: rate.comment,
          name: rate.title_comment,
          reviewRating: {
            "@type": `Rating`,
            bestRating: `5`,
            ratingValue: rate.score,
            worstRating: `1`,
          },
          author: {
            "@type": `Person`,
            name: rate.client.name,
          },
        })),
        isRelatedTo: simpleMicroProduct(`${business.webUrl}${domain}`, related),
        isSimilarTo: simpleMicroProduct(`${business.webUrl}${domain}`, canonicals),
        aggregateRating:
        reviewCount > 0 ?
          {
            "@type": `AggregateRating`,
            ratingValue,
            reviewCount,
          } :
          undefined,
      },
    ])
  }, [product])
  useEffect(() => {
    const name = `micro-product`
    constructScript(`script`, name, prod)

    return () => {
      removeScript(name)
    }
  }, [prod])

  return null
}

export const MicroProductFaceBook = ({ product, brand, category }) => {
  const metaTags = useMemo(() => ({
    "product:brand": brand?.name ?? ``,
    "product:availability": product.variant?.some(x => x.external.disponibility !== 3) ? `In stock` : `out of stock`,
    "product:condition": conditionProduct(product, true),
    "product:price:amount": fixTwoDecimal(product?.price?.cost || product?.pack?.cost),
    "product:price:currency": `EUR`,
    "product:retailer_item_id": product?.id,
    "product:item_group_id": category,
    "product:category": product.breadcrumbs[0]?.h1,
    ...product.breadcrumbs.length > 2 && {
      "product:custom_label_0": product.breadcrumbs[1].h1
    }
  }), [ product, brand, category ])

  useEffect(() => {
    // Create all meta tags at once
    const metaElements = Object.entries(metaTags).map(([ property, content ]) => {
      const meta = document.createElement(`meta`)
      meta.setAttribute(`property`, property)
      meta.setAttribute(`content`, content)
      return meta
    })

    metaElements.forEach(meta => document.head.appendChild(meta))

    // Cleanup function
    return () => metaElements.forEach(meta => meta.remove())
  }, [metaTags])

  return null
}
