import { constants } from '@/utils/global'
import Rating from '@mui/material/Rating'

const { RATING } = constants

const RatingSummary = ({ reviews, ratings }) => {
  return (
    <div id="product-rating" className="flex items-center">
      <Rating name="half-rating-read" value={parseFloat(reviews.score) || 0} {...{ precision: 0.5, readOnly: true }} />
      <a href={`#${RATING.name}`}>
        <p className="px-2 text-neutral-500 underline underline-offset-2">{`${reviews.count} ${parseInt(reviews.count ?? 0, 10) === 1 ? ratings.opinion : ratings.opinions}`}</p>
      </a>
    </div>
  )
}

export default RatingSummary
