import { getImageUrl } from "@/utils/functions/images/imagesHelper"
import { BsPlus } from "react-icons/bs"
import { FaEquals } from "react-icons/fa"
import Relations from "./Relations"
import Image from "next/image"

export const Relateds = ({ ...props }) => {
  'use memo'
  const {
    type, image, relateds, hostname, position, origin, show = true
  } = props
  const {
    business, domain, colors
  } = hostname

  const firstImageUrl = show ? getImageUrl(image.image, business.thumb, domain) : null
  return (
    <div className="flex justify-start items-start overflow-hidden">
      {show && <>
        <Image
          src={firstImageUrl}
          alt={image.alt}
          title={image.title}
          loading="lazy"
          width={300}
          height={300}
          className={`rounded-2xl contrast-[0.925] size-44 hidden sm:block`}
          fetchPriority="high"
          decoding="async"
          unoptimized={true}
        />
        <div className=" h-[175px] hidden sm:flex"></div>
        <div className=" h-[175px] hidden sm:flex">
          {type === `similar` ? <FaEquals className={`m-auto mx-2 size-3`} /> : <BsPlus className={`m-auto mx-1 size-7`} />}
        </div>
      </>}
      <div className="w-full flex overflow-x-auto">
        <Relations
          key={`carousel_${position}`}
          {...{
            hostname,
            prodList: relateds,
            origin,
            colors,
            number: 0,
            position,
            ischild: true,
            small: true
          }}
        />
      </div>
    </div>
  )
}
export default Relateds
