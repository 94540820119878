'use client'
import { useRelateds } from "@/hooks/relations/useRelateds"
import { Suspense } from "react"
import Relateds from "./Relateds"
export const experimental_ppr = true
const ProductRelateds = ({ ...props }) => {
  'use memo'
  const {
    product: { relateds, product_id }, hostname, titleText, origin, show = true
  } = props
  const optRelateds = useRelateds({ hostname, relateds: relateds[origin], product_id })
  return !!optRelateds.length && (
    <Suspense>
      {show && <h2 className={`mt-3 sm:mb-3 sm:mt-0`}>
        {titleText}
      </h2>}
      <Relateds {...props} {...{ relateds: optRelateds ?? []}} />
    </Suspense>
  )
}

export default ProductRelateds
