'use client'

import TWInput from "@/components/tailwind/Input"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { handleKeyRandom } from "@/utils/functions"
import { useContext, useEffect, useId, useMemo, useState } from "react"
import { LuSearch } from "react-icons/lu"
import { BrandCard } from "./BrandCard"
import { LoadingContext } from "@/context/tools/loading"
import { ToolsContext } from "@/context/tools/tools"

export const BodyBrands = props => {
  const { brandList, hostname: { colors }} = props
  const [ text, setText ] = useState(``)
  const { tablet } = useMediaQuerys()
  const { modules } = useContext(LoadingContext)
  const { tools: { texts }} = useContext(ToolsContext)
  const pageTexts = useMemo(() => texts.catalogue_page, [texts])
  const keyId = useId()

  useEffect(() => {
    modules.handleLinkLoading(false)
  }, [brandList])

  return (
    <div>
      <div style={{ position: `sticky`, zIndex: 10, top: tablet ? 65 : 45 }} className="tablet:flex bg-white py-2 border-b-[1px]">
        <div className="tablet:w-[60%] rounded-full border-[1px]" style={{ borderColor: colors.lightGrey3 }}>
          <TWInput { ...{ colors, onChange: e => setText(e.target.value) }} placeholder={pageTexts?.our_brands ?? ``}
            className="py-0 pr-0"
            type={`text`}
            endicon={LuSearch}
          />
        </div>
        <div className="rounded-full self-center text-center mt-1 w-[120px] ml-1 p-[1px] bg-slate-100 text-gray-700 text-sm border-[1px] tablet:mt-0 tablet:p-2"
          style={{ borderColor: colors.lightGrey3 }}>
          {`${brandList.filter(x => x.webdata.h1?.toLowerCase().includes(text.toLowerCase()) || text === ``).length} ${pageTexts?.results}` ?? ``}
        </div>
      </div>
      <div id="brands-catalogue" className="flex flex-wrap min-h-5 justify-evenly py-[40px]">
        {brandList.
          filter(x => x.webdata.h1?.toLowerCase().includes(text?.toLowerCase()) || text === ``).
          map((x, i) => (
            <BrandCard key={handleKeyRandom(keyId, i)} {...x} {...props} {...{ modules }} />
          ))}
      </div>
    </div>
  )
}
